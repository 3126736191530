html, body, #root {
  height: 100%;
}

.App {
  width: 100%;
  height: 100%;
  display: flex;
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  font-family: Helvetica, sans-serif, Arial;
  color: rgb(0, 0, 69);
}

.portrait {
  width: 300px;
  height: 380px;
}

iframe {
  width: 18%;
  height: 40%;
}

@media screen and (max-width: 500px) {
  iframe {
    width: 27%;
  }
}

p {
  width: 75%;
  text-indent: 30px;
  line-height: 150%;
}

button {
  height: 30px;
  background-color: rgb(113, 113, 248);
}

.nav {
  list-style-type: none;
  width: 50%;
  display: flex;
  justify-content: space-evenly;
}

.ln {
  width: 35%;
  margin-top: -1px;
  border: .5px solid #b4b4b4
}